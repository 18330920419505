export const config = {
    RPC_URL: 'https://rpc-testnet.zodiatic.io',
    REST_URL: 'https://api-testnet.zodiatic.io',
    EXPLORER_URL: 'https://explorer.zodiatic.io',
    NETWORK_NAME: 'Zodiatic-testnet',
    NETWORK_TYPE: 'testnet',
    CHAIN_ID: 'zodiatic-testnet-1',
    CHAIN_NAME: 'Zodiatic-testnet',
    COIN_DENOM: 'RMBO',
    COIN_MINIMAL_DENOM: 'urmbo',
    COIN_DECIMALS: 6,
    PREFIX: 'zodiatic',
    COIN_TYPE: 118,
    COINGECKO_ID: 'unknown',
    DEFAULT_GAS: 250000,
    GAS_PRICE_STEP_LOW: 0.000,
    GAS_PRICE_STEP_AVERAGE: 0.000,
    GAS_PRICE_STEP_HIGH: 0.000,
    FEATURES: ['cosmwasm', 'ibc-transfer', 'ibc-go'],
};
